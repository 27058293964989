<template>
  <div class="payer_detail credit_holder_main_area">
    <div class="row">
      <div class="col-md-6" v-if="device == 'desktop'">
        <div class="credit_holder_image_box">
          <img src="/assets/img/new2.jpg" alt="" title="" />
        </div>
      </div>
      <div class="col-md-6">
        <div class="credit_holder_form_area">
          <div class="header_box">
            <h3>
              <span class="icon"><i class="fas fa-credit-card"></i></span>
              {{ $t("booking.payer-details") }}
            </h3>
          </div>
          <div class="body_box">
            <div class="form-group">
              <div class="form-group col-6 m-0 p-0">
                <input
                  type="text"
                  :class="['form-control', !stateFirstName ? 'danger' : '']"
                  :placeholder="$t('booking.first-name')"
                  v-model="firstNamePayer"
                  @change="updatingPayerInfo"
                />
                <div class="icon-form" v-if="stateFirstName"><i class="fas fa-check"></i></div>
              </div>
              <div class="form-group col-6 m-0 p-0">
                <input
                  type="text"
                  :class="['form-control', !stateLastName ? 'danger' : '']"
                  :placeholder="$t('booking.last-name')"
                  v-model="lastNamePayer"
                  @change="updatingPayerInfo"
                />
                <div class="icon-form" v-if="stateLastName"><i class="fas fa-check"></i></div>
                <!-- <div class="form-icon"><i class="fas fa-user"></i></div> -->
              </div>
            </div>
            <div class="form-group p-2">
              <label>{{ $t('booking.phone') }}</label>
              <div class="w-100 position-relative ">
                <input
                  type="text"
                  :class="['form-control', !statePhone ? 'danger' : '']"
                  :placeholder="$t('booking.phone')"
                  v-model="phonePayer"
                  @change="updatingPayerInfo"
                />
                <div class="icon-form" v-if="statePhone"><i class="fas fa-check"></i></div>
                <!-- <div class="form-icon"><i class="fas fa-user"></i></div> -->
              </div>
            </div>
            <div class="form-group p-2">
              <label>{{ $t('booking.email') }}</label>
              <div class="w-100 position-relative ">
                <input
                  type="email"
                  :class="['form-control', !stateEmail ? 'danger' : '']"
                  :placeholder="$t('booking.email')"
                  v-model="emailPayer"
                  @change="updatingPayerInfo"
                />
                <div class="icon-form" v-if="stateEmail"><i class="fas fa-check"></i></div>
                <!-- <div class="form-icon"><i class="fas fa-user"></i></div> -->
              </div>
            </div>
            <div class="form-group p-2">
              <label>{{ $t('booking.id-card') }}</label>
              <div class="w-100 position-relative ">
                <input
                  type="text"
                  :class="['form-control', !stateIDCardNumber ? 'danger' : '']"
                  :placeholder="$t('booking.id-card')"
                  v-model="idCardNumberPayer"
                  @change="updatingPayerInfo"
                />
                <div class="icon-form" v-if="stateIDCardNumber"><i class="fas fa-check"></i></div>
              </div>
            </div>
            <div style="display:none;">
              <label class="w-100 d-sm-block d-md-none">{{
                $t("booking.card-validity")
              }}</label>
              <div class="form-group p-1">
                <label class="w-100">{{ $t('booking.card-validity') }}</label>
                <b-form-select
                  v-model="creditCardExpirMonth"
                  :options="months"
                  @change="updatingPayerInfo"
                  style="margin-right: 15px;"
                ></b-form-select>
                <b-form-select
                  v-model="creditCardExpirYear"
                  :options="years"
                  @change="updatingPayerInfo"
                  style="margin-left: 0;"
                ></b-form-select>
              </div>
              <div class="form-group p-2">
                <label>{{ $t('booking.no-of-payment') }}</label>
                <div class="w-100 position-relative ">
                  <b-form-select
                    :class="['form-control', !stateNoOfPayment ? 'danger' : '']"
                    v-model="noOfPayment"
                    :options="[1,2,3,4,5,6,7,8,9,10]"
                    @change="updatingPayerInfo"
                  ></b-form-select>
                  <div class="icon-form" v-if="stateNoOfPayment"><i class="fas fa-check"></i></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BFormSelect } from 'bootstrap-vue';
import { fromBase64 } from '@/utils/base64Util';

export default {
  components: {
    BFormSelect,
  },
  data() {
    return {
      years: [],
      months: [
        { value: '01', text: this.$t('monthName.jan') },
        { value: '02', text: this.$t('monthName.feb') },
        { value: '03', text: this.$t('monthName.mar') },
        { value: '04', text: this.$t('monthName.apr') },
        { value: '05', text: this.$t('monthName.may') },
        { value: '06', text: this.$t('monthName.jun') },
        { value: '07', text: this.$t('monthName.jul') },
        { value: '08', text: this.$t('monthName.aug') },
        { value: '09', text: this.$t('monthName.sep') },
        { value: '10', text: this.$t('monthName.oct') },
        { value: '11', text: this.$t('monthName.nov') },
        { value: '12', text: this.$t('monthName.dec') },
      ],
      firstNamePayer: '',
      lastNamePayer: '',
      phonePayer: '',
      emailPayer: '',
      idCardNumberPayer: '',
      creditCardExpirYear: '',
      creditCardExpirMonth: '01',
      noOfPayment: '1',

      // firstNamePayer: "test",
      // lastNamePayer: "test",
      // phonePayer: "12345545",
      // emailPayer: "test@yahoo.com",
      // idCardNumberPayer: "011111119",
      // creditCardExpirYear: "25",
      // creditCardExpirMonth: "12",
      // noOfPayment : '1',
    };
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      device: 'GET_DEVICE',
      devMode: 'GET_MODE',
    }),
    stateFirstName() {
      return !!this.firstNamePayer;
    },
    stateLastName() {
      return !!this.lastNamePayer;
    },
    stateEmail() {
      return !!this.emailPayer && this.validateEmail(this.emailPayer);
    },
    statePhone() {
      return !!this.phonePayer;
    },
    stateIDCardNumber() {
      return !!this.idCardNumberPayer;
    },
    stateCreditCardExpireYear() {
      return !!this.creditCardExpirYear;
    },
    stateCreditCardExpireMonth() {
      return !!this.creditCardExpirMonth;
    },
    stateNoOfPayment() {
      return !!this.noOfPayment;
    },
  },
  watch: {
    lang: 'updateLableWithLang',
  },
  created() {
    const date = new Date(),
      currYear = date.getFullYear();
    for (let i = 0; i < 10; i += 1) {
      this.years.push({
        value: String(currYear - 2000 + i),
        text: String(currYear + i),
      });
    }
    this.creditCardExpirYear = this.years[0].value;
    const retry = window.localStorage.getItem('retryState');
    const dataBook = JSON.parse(fromBase64(window.localStorage.getItem('BookingDetailInfo')));
    const { payer } = dataBook.data;
    const { sendingStage } = dataBook;

    if (retry !== 'null' && sendingStage !== 'init') {
      if (payer.name.length > 0) {
        const name = payer.name.split(' ');
        this.firstNamePayer = name[0];
        this.lastNamePayer = name[1];
      }
      this.phonePayer = payer.mobile;
      this.emailPayer = payer.email;
      this.idCardNumberPayer = payer.identificationNumber;
      this.creditCardExpirYear = payer.creditCardExpirYear || this.years[0].value;
      this.creditCardExpirMonth = payer.creditCardExpirMonth || '01';
      this.noOfPayment = Number(payer.noOfPayment);
      this.updatingPayerInfo();
    }
    if (this.devMode) {
      if (this.firstNamePayer === '') this.firstNamePayer = 'test';
      if (this.lastNamePayer === '') this.lastNamePayer = 'test';
      if (this.emailPayer === '') this.emailPayer = 'ofirfishler@gmail.com';
      if (this.phonePayer === '') this.phonePayer = '543320148';
      if (this.idCardNumberPayer === '') this.idCardNumberPayer = '011111119';
      if (this.creditCardExpirYear === '') this.creditCardExpirYear = '2025';
      if (this.creditCardExpirMonth === '') this.creditCardExpirMonth = '12';
    }
    this.updatingPayerInfo();
  },
  methods: {
    updateLableWithLang() {
      this.months = [
        { value: '01', text: this.$t('monthName.jan') },
        { value: '02', text: this.$t('monthName.feb') },
        { value: '03', text: this.$t('monthName.mar') },
        { value: '04', text: this.$t('monthName.apr') },
        { value: '05', text: this.$t('monthName.may') },
        { value: '06', text: this.$t('monthName.jun') },
        { value: '07', text: this.$t('monthName.jul') },
        { value: '08', text: this.$t('monthName.aug') },
        { value: '09', text: this.$t('monthName.sep') },
        { value: '10', text: this.$t('monthName.oct') },
        { value: '11', text: this.$t('monthName.nov') },
        { value: '12', text: this.$t('monthName.dec') },
      ];
    },
    updatingPayerInfo() {
      const payer = {
        firstNamePayer: this.firstNamePayer,
        lastNamePayer: this.lastNamePayer,
        phonePayer: this.phonePayer,
        emailPayer: this.emailPayer,
        idCardNumberPayer: this.idCardNumberPayer,
        creditCardExpirYear: this.creditCardExpirYear,
        creditCardExpirMonth: this.creditCardExpirMonth,
        noOfPayment: this.noOfPayment,
        available: this.availablePayerInformation(),
      };
      this.$emit('updatePayer', payer);
    },
    availablePayerInformation() {
      if (
        this.stateFirstName
        && this.stateLastName
        && this.stateEmail
        && this.statePhone
        && this.stateIDCardNumber
        // && this.stateCreditCardExpireYear
        // && this.stateCreditCardExpireMonth
        // && this.stateNoOfPayment
      ) {
        return true;
      } else {
        return false;
      }
    },
    validateEmail(email) {
      const re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
  },
};
</script>

<style scoped>
.credit_holder_main_area {
  margin: 30px 0px 0px;
}

.credit_holder_image_box {
  height: 365px;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 10px;
}

.credit_holder_image_box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.credit_holder_form_area .header_box h3 {
  margin: 0 0;
  color: #ffffff;
  font-size: 22px;
  text-transform: uppercase;
}

.credit_holder_form_area {
  overflow: hidden;
  border-radius: 10px;
}

.credit_holder_form_area .header_box {
  padding: 25px 25px;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    180deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(1, 98, 172, 1) 0%,
    rgba(38, 135, 209, 1) 100%
  );
}

.credit_holder_form_area .body_box {
  padding: 5px 10px;
  border: 1px solid #d3d3d3;
  border-top: 0px;
}

.payer_detail .form-group input,
.payer_detail .form-group select {
  margin: 0px 5px;
}

.form-group {
  display: flex;
  margin: 8px 0px;
}

.form-group label {
  line-height: 20px;
  padding-top: 10px;
  width: 40%;
}

.credit_holder_form_area .body_box label {
  color: rgb(151, 151, 155);
  font-size: 14px;
  margin-bottom: 0px;
}

.form-group .icon-form{
    content: '';
    position: absolute;
    bottom: 5px;
    right: 14px;
    color: #66b3ff;
}

.danger {
  border-color: #FF5733;
  outline: 0;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(233 102 102 / 60%);
}

@media (max-width: 479px) {
  .credit_holder_main_area {
    margin: 5px 0px 0px;
  }
  .price_details_main_body {
    padding: 0px 0px;
  }

  .credit_holder_form_area .header_box {
    background: #fff;
    padding: 5px 5px;
  }

  .credit_holder_form_area .header_box h3 {
    padding: 0 0 7px;
    font-size: 18px;
    font-weight: 600;
    color: #000;
    text-transform: capitalize;
    border-bottom: 2px solid #bfbfbf;
    margin-bottom: 10px;
    position: relative;
    padding-right: 25px;
  }

  .body_box .form-group label {
    display: none;
  }
}
</style>
